import React, { useEffect, useState } from 'react';
import { storage, db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useParams, useNavigate } from 'react-router-dom';

const EditEventForm = () => {
  const { eventId } = useParams();  
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    date: '',
    location: '',
    neighborhood: '',
    coins: 0,
    startTime: '',
    endTime: '',
    doorsOpenTime: '',
    organizer: '',
    reward: '',
    ticketPrice: '',
    ticketQuantity: '',
  });

  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [imageUrl, setImageUrl] = useState('');
  const [message, setMessage] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventRef = doc(db, 'events', eventId);
        const eventSnapshot = await getDoc(eventRef);
        if (eventSnapshot.exists()) {
          setFormData(eventSnapshot.data());  
          setImageUrl(eventSnapshot.data().imageUrl); 
        } else {
          setMessage('Event not found');
        }
      } catch (err) {
        console.error('Error fetching event data: ', err);
      }
    };
    fetchEvent();
  }, [eventId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!imageFile && !imageUrl) {
      setMessage('Please select an image.');
      return;
    }

    try {
      let updatedImageUrl = imageUrl;

      if (imageFile) {
        const imageRef = ref(storage, `event_images/${imageFile.name}`);
        const uploadTask = uploadBytesResumable(imageRef, imageFile);
        setUploading(true);
        await uploadTask.then();
        updatedImageUrl = await getDownloadURL(uploadTask.snapshot.ref);
      }

      const eventRef = doc(db, 'events', eventId);
      await updateDoc(eventRef, {
        ...formData,
        imageUrl: updatedImageUrl,
      });

      setMessage('Event updated successfully!');
      setUploading(false);
      navigate(`/events/${eventId}`);
    } catch (error) {
      console.error('Error updating event: ', error);
      setMessage('Error updating event. Please try again.');
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Edit Event</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        {/* Event Name */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Event name (Max 50 characters)</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            maxLength="50"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>
        
        {/* Event Description */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Event Description (Max 200 characters)</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            rows="4"
            maxLength="200"
            required
          />
        </div>

        {/* Event Date */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Event Date</label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Event Start Time */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Event Start Time</label>
          <input
            type="time"
            name="startTime"
            value={formData.startTime}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Event End Time */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Event End Time</label>
          <input
            type="time"
            name="endTime"
            value={formData.endTime}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Doors Open Time */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Doors Open Time</label>
          <input
            type="time"
            name="doorsOpenTime"
            value={formData.doorsOpenTime}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Location */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Location</label>
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Neighborhood */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Neighborhood</label>
          <input
            type="text"
            name="neighborhood"
            value={formData.neighborhood}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Number of NeighborhoodCoins */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">NeighborhoodCoins</label>
          <input
            type="number"
            name="coins"
            value={formData.coins}
            onChange={handleChange}
            max="5"
            min="0"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Ticket Price (Optional) */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Ticket Price ($) (Optional)</label>
          <input
            type="number"
            name="ticketPrice"
            value={formData.ticketPrice}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Ticket Quantity (Optional) */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Ticket Quantity (Optional)</label>
          <input
            type="number"
            name="ticketQuantity"
            value={formData.ticketQuantity}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Image Upload */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Event Image</label>
          <input
            type="file"
            onChange={handleImageChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        {uploading ? (
          <p>Uploading image...</p>
        ) : (
          <button type="submit" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight">
            Update Event
          </button>
        )}
      </form>
    </div>
  );
};

export default EditEventForm;