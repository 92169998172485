import React from 'react';

const OnboardingModal = ({ closeModal }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto">
        <h2 className="text-2xl font-bold mb-4">Welcome to Neighborhood Coin!</h2>
        <p className="mb-4">
          You've just signed up for a platform that connects you with your neighborhood through events and rewards.
          Here's what you can do:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Explore local events and earn Neighborhood Coins.</li>
          <li>Join events and get involved with your community.</li>
          <li>Use your Neighborhood Coins to redeem rewards.</li>
        </ul>
        <button
          onClick={closeModal}
          className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight"
        >
          Got it, take me to the Dashboard!
        </button>
      </div>
    </div>
  );
};

export default OnboardingModal;