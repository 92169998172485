import React, { useState } from 'react';
import { functions, auth, storage, db } from '../firebase';  
import { addDoc, collection } from 'firebase/firestore'; 
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';  // Firebase Storage methods
import { httpsCallable } from 'firebase/functions';
import axios from 'axios';
import Map from  './Map';

const EventForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    date: '',
    location: '',//This is the address of the event
    venue: '',
    neighborhood: '',
    coins: 0,
    startTime: '',
    endTime: '',
    doorsOpenTime: '',
    organizer: '',
    reward: '',
    ticketPrice: '', // Allows empty ticket pricing
    ticketQuantity: '', // Allows empty ticket quantity
  });
  const [latLong, setLatLong] = useState(null);
  const [mapLoading, setMapLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [imageUrl, setImageUrl] = useState('');
  const [message, setMessage] = useState(null);
  const [uploading, setUploading] = useState(false); 

 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const geocodeAddress = async (address) => {
    try {
      setMapLoading(true);
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
        params: {
          address,
          key: process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY, // Your API key
        }
      });
      console.log(response.data);  // Log the response to check
      if (response.data.results.length > 0) {
        const location = response.data.results[0].geometry.location;
        setLatLong({ lat: location.lat, lng: location.lng }); // Save the latitude and longitude
        return { lat: location.lat, lng: location.lng }; // Return latitude and longitude
      } else {
        console.error("No results found for the provided address");
        return null; // Return null if geocoding fails
      }
    } catch (error) {
      console.error("Geocoding API error: ", error);
      return null; // Return null in case of error
    } finally {
      setMapLoading(false);
    }
  };

// Call geocodeAddress when user finishes typing in the location field
const handleAddressBlur = async (e) => {
  const { value } = e.target;
  if (value) {
    const location = await geocodeAddress(value);
    if (location) {
      // Successfully got lat/lng, you can store it in state or pass it to a function
      setLatLong(location);  // This stores lat/lng for later use
      console.log('Lat/Long:', location.lat);  // Check if it logs the correct values
    } else {
      console.error('Failed to fetch lat/long for the provided address');
    }
  }
};

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);  // Store the selected image file
  };

  // Validation
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.description.trim()) {
      newErrors.description = 'Description is required';
    }
    
    if (!formData.date) {
      newErrors.date = 'Date is required';
    }

    if (!formData.location.trim()) {
      newErrors.location = 'Location is required';
    }

    if (formData.coins < 1 || formData.coins > 5) {
      newErrors.coins = 'Please enter a valid number of NeighborhoodCoins (1-5)';
    }

    // Ticket validation
    if (formData.ticketPrice && formData.ticketPrice < 0) {
      newErrors.ticketPrice = 'Ticket price cannot be negative';
    }
    if (formData.ticketQuantity && formData.ticketQuantity < 1) {
      newErrors.ticketQuantity = 'You must have at least 1 ticket available';
    }

    // Validate time (start time must be after door open time)
    if (formData.doorsOpenTime && formData.startTime && formData.startTime < formData.doorsOpenTime) {
      newErrors.doorsOpenTime = 'Doors Open time must be before the start time';
    }

    return newErrors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate form
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    // Fetch lat/long based on the address
    const location = await geocodeAddress(formData.location);
    if (!location) {
      setMessage("Invalid address.");
      return;
    }
  
    // Proceed to store form data and lat/long in Firestore or pass it elsewhere
    try {
      const eventData = {
        ...formData,
        latitude: location.lat,  // Store latitude
        longitude: location.lng,  // Store longitude
      };
  
      const eventsRef = collection(db, 'events');
      await addDoc(eventsRef, eventData);  // Save to Firestore
      setMessage('Event successfully added!');
    } catch (error) {
      console.error('Error adding event: ', error);
      setMessage('Error adding event. Please try again.');
    }
  };


  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Create a New Event</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        {/* Event Name */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Event name (Max 50 characters)</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            maxLength="50"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>
           {/* Event Description */}
        <div className="mb-4">
            <label className="block text-neutral mb-2">Event Description (Max 200 characters)</label>
            <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                rows="4"
                placeholder="Enter event description"
                maxLength="200"
                required
            ></textarea>
                {errors.description && <p className="text-red-500 text-xs mt-1">{errors.description}</p>}

        </div>
      
           {/* Date */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Event Date</label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>
         {/* Event Start Time */}
         <div className="mb-4">
          <label className="block text-neutral mb-2">Event Start Time</label>
          <input
            type="time"
            name="startTime"
            value={formData.startTime}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>

        {/* Event End Time */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Event End Time</label>
          <input
            type="time"
            name="endTime"
            value={formData.endTime}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>

        {/* Doors Open Time */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Doors Open Time</label>
          <input
            type="time"
            name="doorsOpenTime"
            value={formData.doorsOpenTime}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>
        {/* Venue  Name */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Event Venue Name</label>
          <input
            type="text"
            name="venue"
            value={formData.venue}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            required
          />
           {errors.date && <p className="text-red-500 text-xs mt-1">{errors.date}</p>}
        </div>
       

       {/* Location */}
<div className="mb-4">
  <label className="block text-neutral mb-2">Event address</label>
  <input
    type="text"
    name="location"
    value={formData.location}
    onChange={handleChange}
    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
    onBlur={handleAddressBlur}
    required
  />
  
</div>
        {/* Number of Neighborhood Coins (limit to 5) */}
        <div className="mb-4">
          <label className="block text-neutral mb-2"> NeighborhoodCoins (Max 5) </label>
          <input
            type="number"
            name="coins"
            value={formData.coins}
            onChange={handleChange}
            max="5"
            min="0"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>
        {/* Reward Offering */}
               <div className="mb-4">
          <label className="block text-neutral mb-2"> Reward Offering </label>
          <input
            type="string"
            name="reward"
            placeholder="Enter reward ie 30% off products"
            value={formData.reward}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        {/* Event Image */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Event Image</label>
          <input
            type="file"
            onChange={handleImageChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Neighborhood (Optional)</label>
          <input
            type="text"
            name="neighborhood"
            value={formData.neighborhood}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            placeholder="Optional"
          />
        </div>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Ticket Price ($) (Optional)</label>
          <input
            type="number"
            name="ticketPrice"
            value={formData.ticketPrice}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            placeholder="Optional"
          />
        </div>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Ticket Quantity (Optional)</label>
          <input
            type="number"
            name="ticketQuantity"
            value={formData.ticketQuantity}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            placeholder="Optional"
          />
        </div>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Organizer Company Name</label>
          <input
            type="text"
            name="organizer"
            value={formData.organizer}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>
        {uploading ? (
          <p>Uploading image...</p>
        ) : (
          <button type="submit" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight">
            Add Event
          </button>
        )}
      </form>
    </div>
  );
};

export default EventForm;