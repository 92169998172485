import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import Map from '../components/Map';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon } from 'react-share';
import { QrReader } from 'react-qr-reader';  // Import QR Code Reader
import CoinImage from '../assets/coin_blank.png';
import { Helmet } from 'react-helmet-async';

const EventDetails = () => {
  const shareUrl = window.location.href;  // Current page URL for sharing
  const { eventId } = useParams();  // Get the event ID from the URL
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rsvpStatus, setRsvpStatus] = useState(false);
  const [checkedIn, setCheckedIn] = useState(false); // Check-in state
  const [showQRScanner, setShowQRScanner] = useState(false); // Show or hide QR scanner

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventRef = doc(db, 'events', eventId);
        const eventSnapshot = await getDoc(eventRef);

        if (eventSnapshot.exists()) {
          const eventData = eventSnapshot.data();
          setEvent(eventData);

          // Check if the current user has RSVP'd to this event
          const user = auth.currentUser;
          if (user && eventData.rsvps && eventData.rsvps.includes(user.email)) {
            setRsvpStatus(true);
          }
        } else {
          setError('Event not found');
        }
      } catch (err) {
        setError('Failed to fetch event details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId]);

  const handleRsvp = async () => {
    const user = auth.currentUser;

    if (!user) {
      setError('You need to be logged in to RSVP.');
      return;
    }

    try {
      const eventRef = doc(db, 'events', eventId);

      // Update the event document by adding the user's email to the RSVPs array
      await updateDoc(eventRef, {
        rsvps: arrayUnion(user.email)
      });

      setRsvpStatus(true);
    } catch (err) {
      setError('Failed to RSVP: ' + err.message);
    }
  };

  const handleCheckIn = async (qrData) => {
    const user = auth.currentUser;

    if (!user) {
      setError('You need to be logged in to check in.');
      return;
    }

    if (qrData === eventId) {
      try {
        const eventRef = doc(db, 'events', eventId);
        
        // Mark user as checked in by adding email to 'checkIns' array
        await updateDoc(eventRef, {
          checkIns: arrayUnion(user.email),
        });

        setCheckedIn(true);
      } catch (err) {
        setError('Failed to check in: ' + err.message);
      }
    } else {
      setError('Invalid QR code for this event.');
    }

    setShowQRScanner(false); // Hide QR scanner after check-in
  };

  if (loading) return <p>Loading event details...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      {event && (
        <>
          {/* Helmet for dynamic SEO and social media sharing */}
      
          <Helmet>
            <title>{event.name} - Neighborhood Coins</title>
            <meta name="description" content={event.description} />
            <meta property="og:title" content={event.name} />
            <meta property="og:description" content={event.description} />
            <meta property="og:image" content={event.imageUrl} />
            <meta property="og:url" content={shareUrl} />
          </Helmet>
       

          <h1 className="text-3xl font-bold text-primary mb-4">{event.name}</h1>
          
          <div className="relative">
            <img src={event.imageUrl} alt={event.name} className="w-full h-48 object-cover rounded-lg mb-4" />
                <div className="absolute bottom-2 right-2 text-white py-1 px-3 rounded-full flex items-center justify-center w-20 h-20" style={{
                  backgroundImage: `url(${CoinImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
            }}>
              <p className="text-center"><span className="block text-sm tex-shadow">Claim</span><strong className=" block text-shadow"> {event.coins}
                </strong><span className="block text-sm text-shadow"> Coins</span>
              </p>
            </div>
          </div>

          <p className="text-neutral mb-2">
            <strong>Date:</strong> {(() => {
              const date = event.date instanceof Object && event.date.toDate
                ? event.date.toDate()
                : new Date(event.date);
              return date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              });
            })()}
          </p>
          <p className="text-neutral mb-4">{event.description}</p>
          <p className="text-neutral mb-4"><strong>Location:</strong> {event.location}</p>
          <p className="text-neutral mb-4"><strong>Organizer:</strong> {event.organizer}</p>

          <div className="mt-6">
            <Map event={event} />
          </div>

          <div className="mt-6">
            {/* RSVP Button and Check-In Section */}
            <div className="flex space-x-4 mt-6">
              {rsvpStatus ? (
                <p className="text-green-600">You have RSVP'd to this event!</p>
              ) : (
                <button
                  className="mt-4 bg-primary text-white py-2 px-4 rounded hover:bg-highlight transition-colors duration-300"
                  onClick={handleRsvp}
                >
                  RSVP / Join
                </button>
              )}
              {/* Check-In Section */}
              {checkedIn ? (
                <p className="text-green-600">You have checked in to this event!</p>
              ) : (
                <>
                  <button
                    className="mt-4 bg-primary text-white py-2 px-4 rounded hover:bg-highlight transition-colors duration-300"
                    onClick={() => setShowQRScanner(true)}
                  >
                    Check In to Event
                  </button>

                  {showQRScanner && (
                    <QrReader
                      onResult={(result, error) => {
                        if (!!result) {
                          handleCheckIn(result?.text);
                        }
                        if (!!error) {
                          console.error(error);
                        }
                      }}
                      style={{ width: '100%' }}
                    />
                  )}
                </>
              )}
            </div>
          </div>

          {/* Social Share Buttons */}
          <div className="flex space-x-4 mt-6">
            <FacebookShareButton url={shareUrl} quote={event.title}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={event.title}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <LinkedinShareButton url={shareUrl} title={event.title}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <WhatsappShareButton url={shareUrl} title={event.title}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>

          {/* Back to Events */}
          <button
            onClick={() => navigate('/events')}  // Navigate back to Events page
            className="bg-secondary text-white py-2 px-4 m-3 rounded hover:bg-highlight transition-colors duration-300"
          >
            Back to Events
          </button>

          {/* Edit Button */}
          {auth.currentUser && auth.currentUser.uid === event.organizerId && (
            <button
              onClick={() => navigate(`/edit-event/${eventId}`)}  // Navigate to the EditEventForm page
              className="bg-secondary text-white py-2 px-4 rounded hover:bg-highlight transition-colors duration-300 mt-4"
            >
              Edit Event
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default EventDetails;