import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import community from '../assets/city_people.png';
import EventForm from '../components/EventForm';
import RoleSignUp from '../components/RoleSignUp';

function Profile() {
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({ displayName: '', email: '' });
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch user data from Firestore using UID
  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userRef = doc(db, 'users', user.uid);  // Always use UID as document ID
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserData(data);
            setFormData({
              displayName: data.displayName || '',
              email: user.email || '', // Use Firebase auth for email
            });
          } else {
            // If no document exists, create a new one
            const newUserData = {
              displayName: user.displayName || 'Anonymous',
              email: user.email || '',
              coins: 0, // Initialize NeighborhoodCoin balance
            };
            await setDoc(userRef, newUserData);  // Use UID as document ID
            setUserData(newUserData);
            setFormData(newUserData);
          }
        } catch (err) {
          console.error('Error fetching user data:', err);
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle form submission and update Firestore using UID
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (user) {
      try {
        const userRef = doc(db, 'users', user.uid);  // Use UID for document reference
        await updateDoc(userRef, {
          displayName: formData.displayName,
        });
        setUserData({ ...userData, displayName: formData.displayName });
        setMessage('Profile updated successfully!');
        setEditMode(false);
      } catch (err) {
        console.error('Failed to update profile:', err);
      }
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!auth.currentUser) {
    return (
      <div className="max-w-md mx-auto bg-white p-4 rounded-lg shadow-lg text-center">
        <div>
          <img src={community} alt="Neighbors gathering" />
        </div>
        <p className="mb-4">You are not logged in.</p>
        <div>
          <RoleSignUp />
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto bg-white p-4 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-accent mb-6 text-center">Profile</h2>
      {userData ? (
        <>
          <div className="mb-4">
            <label className="block text-neutral mb-2">Name:</label>
            {editMode ? (
              <input
                type="text"
                name="displayName"
                value={formData.displayName}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              />
            ) : (
              <p className="text-neutral">{userData.displayName}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-neutral mb-2">Email:</label>
            <p className="text-neutral">{userData.email}</p>
          </div>

         
          <div className="mb-4">
            <label className="block text-neutral mb-2">Neighborhood Coins Balance:</label>
            <p className="text-neutral font-bold">{Number(userData.neighborCoinBalance)}</p>
          </div>
          {editMode ? (
            <button
              onClick={handleFormSubmit}
              className="bg-primary text-white py-2 px-4 rounded hover:bg-highlight transition-colors duration-300"
            >
              Save Changes
            </button>
          ) : (
            <button
              onClick={() => setEditMode(true)}
              className="bg-primary text-white py-2 px-4 rounded hover:bg-highlight transition-colors duration-300"
            >
              Edit Profile
            </button>
          )}
          {message && <p className="text-green-600 mt-4">{message}</p>}
        </>
      ) : (
        <p>No user data found.</p>
      )}
    </div>
  );
}

export default Profile;