import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const ProtectedRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserRole = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid); // Reference to Firestore
        const userDoc = await getDoc(userRef);
        if (userDoc.exists() && userDoc.data().role === 'eventPlanner') {
          setIsAuthorized(true); // If role matches, grant access
        } else {
          setIsAuthorized(false); // Deny access if no role or wrong role
          navigate('/events'); // Redirect to homepage or unauthorized page
        }
      }
      setLoading(false);
    };

    fetchUserRole();
  }, [navigate]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return isAuthorized ? children : <p>Unauthorized</p>;
};

export default ProtectedRoute;