import React, { useEffect, useState } from 'react';
import { getAuth, signInWithEmailLink, isSignInWithEmailLink } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';  // Import Firestore instance
import { useLocation, useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';

const FinishSignUp = () => {
  const [email, setEmail] = useState('');  // Email field for manual entry if not pre-populated
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const auth = getAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the URL contains a valid sign-in link
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const urlParams = new URLSearchParams(window.location.search);
      const pendingEmail = urlParams.get('email') || window.localStorage.getItem('emailForSignIn');

      if (pendingEmail) {
        // Proceed with the email from the link
        handleSignIn(pendingEmail);
      } else {
        setMessage('Please confirm your email address');
      }
    } else {
      setError('Invalid sign-in link.');
    }
  }, [auth, location, navigate]);

  const handleSignIn = async (emailForSignIn) => {
    try {
      // Sign in using the provided email and the email link in the URL
      const result = await signInWithEmailLink(auth, emailForSignIn, window.location.href);
      window.localStorage.removeItem('emailForSignIn');
      setMessage('Successfully signed in!');

      // Fetch ID token for session cookie creation
      const idToken = await result.user.getIdToken();

      // Use Firebase Functions to create a secure session cookie
      const functions = getFunctions();
      const createSessionCookie = httpsCallable(functions, 'createSessionCookie');
      const response = await createSessionCookie({ idToken });

      if (response.data.sessionCookie) {
        // Store session cookie with secure, HTTP-only attributes
        document.cookie = `session=${response.data.sessionCookie}; path=/; Secure; HttpOnly; SameSite=Strict;`;

        // First, check the 'pendingUsers' collection
        const pendingUserRef = doc(db, 'pendingUsers', emailForSignIn);
        const pendingUserDoc = await getDoc(pendingUserRef);

        if (pendingUserDoc.exists()) {
          const { role } = pendingUserDoc.data();

          // Move the user to the 'users' collection
          await setDoc(doc(db, 'users', result.user.uid), {
            email: result.user.email,
            name: result.user.displayName || 'Anonymous',
            neighborCoinBalance: 0,
            createdAt: new Date(),
            role: role,  // Move the assigned role to the user's document
          });

          // Remove the user from 'pendingUsers'
          await setDoc(pendingUserRef, {}, { merge: false });

          // Redirect based on user role
          if (role === 'eventPlanner') {
            navigate('/event-admin');
          } else if (role === 'businessOwner') {
            navigate('/business-dashboard');
          } else {
            navigate('/dashboard');
          }

        } else {
          // If no data is found in 'pendingUsers', check 'users' collection
          const userRef = doc(db, 'users', result.user.uid);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const { role } = userDoc.data();

            // Redirect based on user role
            if (role === 'eventPlanner') {
              navigate('/event-admin');
            } else if (role === 'businessOwner') {
              navigate('/business-dashboard');
            } else {
              navigate('/dashboard');
            }

          } else {
            // If user does not exist in either collection, handle it
            setError('User role not found. Please contact support.');
          }
        }
      } else {
        setError('Failed to create session cookie.');
      }
    } catch (err) {
      setError(`Error signing in: ${err.message}`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Proceed with the email manually entered by the user
    handleSignIn(email);
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Finish Sign Up</h2>
      {message && <p>{message}</p>}
      {error && <p className="text-red-500">{error}</p>}

      {/* Show the email input field if the email is not pre-filled */}
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg mb-4"
          placeholder="Enter your email"
        />
        <button type="submit" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight">
          Submit
        </button>
      </form>
    </div>
  );
};

export default FinishSignUp;