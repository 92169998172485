import React, { useState } from 'react';
import { auth, db } from '../firebase'; // Import Firebase instance
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const CreateOffer = () => {
  const [formData, setFormData] = useState({
    offerTitle: '',
    offerDescription: '',
    discountPercentage: '',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const user = auth.currentUser;

    if (!user) {
      setError('You need to be logged in to create an offer.');
      return;
    }

    try {
      // Add offer details to Firestore under the 'offers' collection
      const offerRef = await addDoc(collection(db, 'offers'), {
        ...formData,
        businessId: user.uid,
        createdAt: new Date(),
      });

      setMessage('Offer created successfully!');
      navigate('/business-dashboard');  // Redirect to dashboard after successful offer creation
    } catch (err) {
      setError(`Failed to create offer: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Create a New Offer</h2>
      {message && <p className="text-green-500">{message}</p>}
      {error && <p className="text-red-500">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Offer Title:</label>
          <input
            type="text"
            name="offerTitle"
            value={formData.offerTitle}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Offer Description:</label>
          <textarea
            name="offerDescription"
            value={formData.offerDescription}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Discount Percentage:</label>
          <input
            type="number"
            name="discountPercentage"
            value={formData.discountPercentage}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <button
          type="submit"
          className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight"
          disabled={loading}
        >
          {loading ? 'Creating Offer...' : 'Create Offer'}
        </button>
      </form>
    </div>
  );
};

export default CreateOffer;