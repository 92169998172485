import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCLME-fF1Ye6Rt9CxlDQCFOaqVG6nwMbGg",
  authDomain: "neighborcoin-8c7ee.firebaseapp.com",
  projectId: "neighborcoin-8c7ee",
  storageBucket: "neighborcoin-8c7ee.appspot.com",
  messagingSenderId: "960517179231",
  appId: "1:960517179231:web:183268597002978d4a18ea",
  measurementId: "G-T696PC021Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);

// Initialize Firebase Authentication and Firestore
const auth = getAuth(app);
const db = getFirestore(app);

// Set session persistence
setPersistence(auth, browserSessionPersistence)
  .then(() => {
    // Persistence is set to browserSessionPersistence
    // Existing and future auth states are now persisted with session persistence
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

// Initialize Firebase Storage
const storage = getStorage(app);

export { app, auth, db, storage, functions };