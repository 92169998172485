import React, { useState } from 'react';
import { db } from '../firebase';  // Import Firebase
import { collection, addDoc } from 'firebase/firestore';  // Firestore methods
import { useNavigate } from 'react-router-dom';

const SignUps = () => {
  const [formData, setFormData] = useState({
    email: '',
    type: 'Event Planner', // Default option
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Email validation regex
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateEmail(formData.email)) {
      setError('Please enter a valid email address.');
      setLoading(false);
      return;
    }

    try {
      // Add data to Firestore
      await addDoc(collection(db, 'signups'), {
        email: formData.email,
        type: formData.type,
        timestamp: new Date(),
      });

      setMessage('Thank you for signing up! Next let |s register your business on Neighborhood Coins.');
      setFormData({ email: '', type: 'Event Planner' });

      // Redirect to Business Sign-Up if user selected "Business Owner"
      if (formData.type === 'Business Owner') {
        navigate('/business-signup', {
          state: {
            email: formData.email,  // Pass email and type to the Business Sign-Up form
            type: formData.type,
          },
        });
      }

    } catch (error) {
      console.error('Error signing up: ', error);
      setMessage('Failed to sign up. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center text-accent">Sign Up for Updates</h2>
      <p className="text-neutral mb-4">
        Sign up as an Event Planner or Business Owner to receive updates and a link when our platform is ready.
      </p>

      {message && <strong><p className="text-primary  mb-4">{message}</p></strong>}

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Email Address</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label className="block text-neutral mb-2">I am a:</label>
          <select
            name="type"
            value={formData.type}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          >
            <option value="Event Planner">Event Planner</option>
            <option value="Business Owner">Business Owner</option>
          </select>
        </div>

        <button
          type="submit"
          className="w-full bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight"
          disabled={loading}
        >
          {loading ? 'Signing Up...' : 'Sign Up'}
        </button>
      </form>
    </div>
  );
};

export default SignUps;