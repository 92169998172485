import React from 'react';
import Navbar from './Navbar';

function Layout({ children }) {
  return (
    <div className="max-w-4xl mx-auto min-h-screen bg-neutralLight text-neutral flex flex-col">
      <Navbar />
     
      <main className="flex-grow p-2 bg-neutralLight">
        {children}
      </main>
      <footer className="bg-teal text-mustard p-4 text-xs text-center">
<p>NeighborhoodCoins powered by <a className="text-white-500 underline ml-1" href="https://www.unieros.com"> Unieros LLC </a> </p>
          <p>&copy; 2024 NeighborhoodCoins. All rights reserved.</p>
    
        </footer>
    </div>
  );
}

export default Layout;