import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

// Function to check if the user is an event organizer
export const isEventOrganizer = async (uid) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', uid));
    if (userDoc.exists() && userDoc.data().role === 'eventPlanner') {
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error checking user role:', error);
    return false;
  }
};

// Function to fetch user profile information
export const getUserProfile = async (uid) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', uid));
    if (userDoc.exists()) {
      return userDoc.data();
    }
    return null;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    return null;
  }
};