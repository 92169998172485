import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs, doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import community from '../assets/city_people.png';

const Rewards = () => {
  const [rewards, setRewards] = useState([]);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRewards = async () => {
      try {
        const rewardsCollection = collection(db, 'rewards');
        const rewardsSnapshot = await getDocs(rewardsCollection);
        const rewardsList = rewardsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setRewards(rewardsList);
      } catch (err) {
        setError('Failed to fetch rewards: ' + err.message);
      }
    };

    fetchRewards();
  }, []);

  const handlePurchaseReward = async (reward) => {
    const user = auth.currentUser;
    if (user) {
      try {
        // Check if the user has enough NeighborCoins
        const userRef = doc(db, 'users', user.email);
        const userDoc = await getDoc(userRef);
        const userBalance = userDoc.data().neighborCoinBalance;

        if (userBalance >= reward.cost) {
          // Deduct the cost from the user's NeighborCoin balance
          await updateDoc(userRef, {
            neighborCoinBalance: increment(-reward.cost)
          });
          setMessage(`You have successfully purchased ${reward.name}!`);
        } else {
          setError('Insufficient NeighborCoins');
        }
      } catch (err) {
        setError('Failed to purchase reward: ' + err.message);
      }
    } else {
      setError('You must be logged in to earn these rewards');
    }
  };
  if (!auth.currentUser) {
    return (
      
      <div className="max-w-4xl mx-auto bg-white p-4 rounded-lg shadow-lg text-center">
         <div className="">
      <img src={community} alt="Neighbors gathering" className="" />
      </div>
        <p className="mb-4 text-bold"> Login to start earning Neighborhood Coins. </p>
        <Link to="/" className="bg-primary text-white py-2 px-4 rounded hover:bg-highlight transition-colors duration-300">
          Go to Login
        </Link>
      </div>
    );
  }

  return (
<div className="max-w-4xl mx-auto p-2 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-primary mb-6">Available Rewards</h2>
      {rewards.length === 0 && <p className="text-neutral">No rewards available at this time.</p>}
      <div className="space-y-6">
        {rewards.map(reward => (
          <div key={reward.id} className="p-4 bg-gray-100 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-accent mb-2">{reward.name}</h3>
            <p className="text-neutral mb-4">Cost: <span className="font-medium">{reward.cost}</span> NeighborCoins</p>
            <button
              onClick={() => handlePurchaseReward(reward)}
              className="bg-primary text-white py-2 px-4 rounded hover:bg-highlight transition-colors duration-300"
            >
              Purchase Reward
            </button>
          </div>
        ))}
      </div>
      {message && <p className="text-green-600 mt-4">{message}</p>}
      {error && <p className="text-red-600 mt-4">{error}</p>}
    </div>
  );
};

export default Rewards;