import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase'; // Import Firebase instance
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';

const BusinessDashboard = () => {
  const [businessData, setBusinessData] = useState(null);
  const [events, setEvents] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBusinessData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          // Get business owner details
          const businessDoc = await getDoc(doc(db, 'businesses', user.uid));
          if (businessDoc.exists()) {
            setBusinessData(businessDoc.data());
          } else {
            setError('Business data not found');
          }
          
          // Fetch events associated with this business
          const q = query(collection(db, 'events'), where('businessId', '==', user.uid));
          const querySnapshot = await getDocs(q);
          const eventsList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setEvents(eventsList);

        } catch (err) {
          setError(err.message);
        }
      } else {
        setError('User not authenticated');
      }
    };

    fetchBusinessData();
  }, []);

  const handleCreateOffer = () => {
    // Logic to create new offers for event attendees
    navigate('/create-offer');  // Example of navigating to a new offer creation page
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-primary mb-4">Business Dashboard</h2>

      {error && <p className="text-red-500">{error}</p>}
      
      {businessData && (
        <>
          <div className="mb-6">
            <h3 className="text-xl font-semibold">Business Profile</h3>
            <p><strong>Name:</strong> {businessData.businessName}</p>
            <p><strong>Location:</strong> {businessData.location}</p>
            <button 
              onClick={() => navigate('/edit-business-profile')}
              className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight mt-4"
            >
              Edit Profile
            </button>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold">Your Events</h3>
            {events.length > 0 ? (
              <ul className="list-disc list-inside">
                {events.map((event) => (
                  <li key={event.id}>
                    <Link to={`/events/${event.id}`} className="text-primary hover:underline">
                      {event.name}
                    </Link> - {event.date}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No events linked to your business.</p>
            )}
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold">Create an Offer for Event Attendees</h3>
            <button 
              onClick={handleCreateOffer}
              className="bg-secondary text-white py-2 px-4 rounded-lg hover:bg-highlight mt-4"
            >
              Create Offer
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default BusinessDashboard;