import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import { Link } from 'react-router-dom';
import community from '../assets/city_people.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faUser, faGift, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import OnboardingModal from '../components/OnboardingModal';
import Login from './Login';
import { getUserProfile } from '../services/userService';

const Dashboard = () => {
  const [userRole, setUserRole] = useState(null);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [balance, setBalance] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check if it's user's first visit
    const firstVisit = localStorage.getItem('firstVisit');
    if (!firstVisit) {
      setShowOnboarding(true);
      localStorage.setItem('firstVisit', 'true');
    }

    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.email));
          if (userDoc.exists()) {
            setBalance(userDoc.data().neighborCoinBalance);
            const profile = await getUserProfile(user.uid);
            if (profile) {
              setUserRole(profile.role);
            } else {
              setError("User profile not found");
            }
          } else {
            setError("No user data found");
          }
        } catch (err) {
          setError(err.message);
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, []);

  const handleLogout = async () => {
    const authInstance = getAuth();
    document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    await signOut(authInstance);
  };

  if (loading) {
    return <p>Loading...</p>;
  }
  if (!auth.currentUser) {
    return (
      <div className="max-w-4xl mx-auto bg-white p-4 rounded-lg shadow-lg text-center">
        <div className="">
          <img src={community} alt="Neighbors gathering" className="" />
        </div>
        <p className="mb-4">You are not logged in.</p>
        <div>
          <Login />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-neutralLight p-2 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-primary mb-4">Welcome to the Dashboard</h2>
      {showOnboarding && <OnboardingModal closeModal={() => setShowOnboarding(false)} />}
      {error && <p>{error}</p>}
      {balance !== null ? (
        <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
          <h3 className="text-neutral mb-4">NeighborhoodCoin Balance: <span className="font-bold">{balance}</span></h3>
          <p className="text-center">Use your coins to support curated community events that build meaningful connections.</p>

          {userRole === 'eventPlanner' && (
            <>
              <Link to="/profile">
                <button className="bg-secondary text-white py-2 px-4 rounded hover:bg-accent w-full md:w-auto">
                  <FontAwesomeIcon icon={faUser} className="mr-2" />
                  View Profile
                </button>
              </Link>
              <Link to="/event-admin">
                <button className="bg-highlight text-white py-2 px-4 rounded hover:bg-accent w-full md:w-auto">
                  <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                  Events Admin
                </button>
              </Link>
            </>
          )}

          {userRole === 'attendee' && (
            <>
              <Link to="/profile">
                <button className="bg-secondary text-white py-2 px-4 rounded hover:bg-accent w-full md:w-auto">
                  <FontAwesomeIcon icon={faUser} className="mr-2" />
                  View Profile
                </button>
              </Link>
              <Link to="/events">
                <button className="bg-highlight text-white py-2 px-4 rounded hover:bg-accent w-full md:w-auto">
                  <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                  Events Calendar
                </button>
              </Link>
              <Link to="/rewards">
                <button className="bg-primary text-white py-2 px-4 rounded hover:bg-highlight w-full md:w-auto">
                  <FontAwesomeIcon icon={faGift} className="mr-2" />
                  Earn Rewards
                </button>
              </Link>
            </>
          )}

          <button className="bg-deepRed text-white py-2 px-4 rounded hover:bg-highlight w-full md:w-auto" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
            Logout
          </button>
        </div>
      ) : (
        <p>Loading your data...</p>
      )}
    </div>
  );
};

export default Dashboard;