import React, { useState, useEffect } from 'react';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import { db } from '../firebase';  // Firestore instance
import { doc, setDoc, getDoc } from 'firebase/firestore';  // Firestore methods
import { useNavigate, Link } from 'react-router-dom';

const RoleSignUp = () => {
  const [formData, setFormData] = useState({
    email: '',
    role: 'attendee'
  });

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');  // Store the logged-in user's role

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Check if the user is already authenticated
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsAuthenticated(true);
        setUserEmail(user.email);  // Set the logged-in user's email

        // Fetch the user's role from Firestore
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);  // Set the user's role
        }
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe();  // Cleanup subscription on unmount
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateEmail(formData.email)) {
      setError('Invalid email address');
      setLoading(false);
      return;
    }

    try {
      const auth = getAuth();
      const actionCodeSettings = {
        url: `${window.location.origin}/finishSignUp?role=${formData.role}`,
        handleCodeInApp: true,
      };

      await sendSignInLinkToEmail(auth, formData.email, actionCodeSettings);

      // Store email temporarily in Firestore with role selection
      await setDoc(doc(db, 'pendingUsers', formData.email), {
        email: formData.email,
        role: formData.role,
        createdAt: new Date(),
      });

      setMessage('Check your email for the sign-in link!');
      setFormData({ email: '', role: 'attendee' });
    } catch (err) {
      console.error('Error sending sign-in link:', err); // Log the exact error
      setError('Failed to send sign-in link: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const getDashboardLink = () => {
    switch (userRole) {
      case 'eventPlanner':
        return '/event-admin';
      case 'businessOwner':
        return '/business-dashboard';
      default:
        return '/dashboard';
    }
  };
   // Mapping of internal role values to user-friendly display names
   const roleDisplayNames = {
    attendee: 'Attendee',
    eventPlanner: 'Event Planner',
    businessOwner: 'Business Owner'
  };


  return (
    <div className="max-w-md mx-auto p-6 bg-mustard rounded-lg shadow-lg">
      {/* If user is already authenticated, no need to send sign-in link */}
      {isAuthenticated ? (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-3">Welcome Back! {userEmail}</h2>

          {/* Redirect to the dashboard or user profile */}
          <p className="text-center">
            You can manage your account by visiting your{''} {''}
            <a href={getDashboardLink()} className="text-primary hover:underline">
            {roleDisplayNames[userRole]}{' '}Dashboard
            </a>.
          </p>
        </div>
      ) : (
        // If not authenticated, show the sign-up form
        <>
          <h2 className="text-2xl font-bold mb-3 text-center">Sign Up</h2>
          <h6 className="text-center px-3 mb-3 text-neutralLight">Welcome to Neighborhood Coins</h6>
          {message && <p className="text-lightPurple-500">{message}</p>}
          {error && <p className="text-red-500">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-neutral mb-2">Email Address:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              />
            </div>
            <div className="mb-4">
              <label className="block text-neutral mb-2">I am signing up as:</label>
              <select
                name="role"
                value={formData.role}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              >
                <option value="attendee">Attendee</option>
                <option value="eventPlanner">Event Organizer</option>
                <option value="businessOwner">Business Owner</option>
              </select>
            </div>
            <button
              type="submit"
              className="w-full bg-coral text-white py-2 px-4 rounded-lg hover:bg-highlight"
              disabled={loading}
            >
              {loading ? 'Sign Up ...' : 'Sign Up'}
            </button>
          </form>

       {/* Login Link */}
          <p className="mt-4 text-center">
            Already signed up?{' '}
            <Link to="/login" className="text-primary hover:underline">
              Login here
            </Link>.
          </p>
        </>
      )}
    </div>
  );
};

export default RoleSignUp;