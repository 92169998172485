import React, { useEffect, useState } from 'react';
import { getAuth, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink, onAuthStateChanged,setPersistence, browserLocalPersistence, browserSessionPersistence, inMemoryPersistence  } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authInstance = getAuth();
    setPersistence(authInstance, browserLocalPersistence)
      .then(() => {
    // Check if user is already authenticated
    onAuthStateChanged(authInstance, (user) => {
      if (user) {
        setIsAuthenticated(true); // User is already authenticated
        setMessage(`Welcome back, ${user.email}`);
      } else {
        setIsAuthenticated(false); // No authenticated user
      }
    });

    // Check if the URL contains a sign-in link
    if (isSignInWithEmailLink(authInstance, window.location.href)) {
      const emailForSignIn = window.localStorage.getItem('emailForSignIn');
      if (emailForSignIn) {
        // Complete the sign-in process with the email link
        handleSignIn(emailForSignIn);
      }
    }
  })
  .catch((err) => {
    console.error('Error setting persistence:', err);
  });
}, []);

  const handleSignUp = async (e) => {
    e.preventDefault();

    const authInstance = getAuth();

    // If user is already authenticated, no need to send sign-in link
    if (isAuthenticated) {
      setMessage(`You are already logged in as ${authInstance.currentUser.email}`);
      return;
    }

    const actionCodeSettings = {
      url: `${window.location.origin}/finishSignUp`, // Automatically adapts to the deployed URL
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(authInstance, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email); // Store email for later use
      setMessage('Check your email for the sign-in link!');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSignIn = async (emailForSignIn) => {
    const authInstance = getAuth();
  
    try {
      const result = await signInWithEmailLink(authInstance, emailForSignIn, window.location.href);
      console.log('User signed in successfully', result.user);
  
      // Reference the user's Firestore document by their UID
      const userRef = doc(db, 'users', result.user.uid);
      const userDoc = await getDoc(userRef);
  
      if (!userDoc.exists()) {
        // If the user does not exist, create a new document with default data
        await setDoc(userRef, {
          email: emailForSignIn,
          name: result.user.displayName || 'Anonymous',
          neighborCoinBalance: 0,
          createdAt: new Date(),
          role: 'user'  // Default role if no role is assigned
        });
        setMessage(`Welcome! Your account has been created.`);
      } else {
        // If user exists, check their role
        const userData = userDoc.data();
        if (userData.role === 'eventPlanner') {
          setMessage(`Welcome back, Event Planner ${result.user.email}`);
          // Redirect to event planner dashboard or show event planner-specific features
        } else {
          setMessage(`Welcome back, ${result.user.email}`);
        }
      }
  
      window.localStorage.removeItem('emailForSignIn'); // Clear saved email after sign-in
    } catch (err) {
      setError(`Error signing in: ${err.message}`);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-mustard p-8 rounded-lg shadow-lg">
      <h2 className="font-bold text-2xl text-neutralLight mb-6 text-center">LOGIN</h2>
      <form onSubmit={handleSignUp}>
        <div className="mb-4">
          <input
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-primary"
          />
        </div>
        {message && <strong><p className="text-greenDark text-center mb-4">{message}</p></strong>}
        {error && <p className="text-red-600 mb-4">{error}</p>}
        <button
          type="submit"
          className="w-full bg-coral text-white py-2 px-4 rounded hover:bg-burntOrange transition-colors duration-300"
        >
          Send Sign-In Link
        </button>
      </form>
    </div>
  );
};

export default SignUp;