import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase'; // Import Firebase instance
import { doc, setDoc } from 'firebase/firestore'; // Firestore methods
import { useLocation, useNavigate } from 'react-router-dom';

const BusinessSignUp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    businessName: '',
    location: '',
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    // Prepopulate the email field if it was passed from SignUps
    if (location.state?.email) {
      setFormData((prev) => ({ ...prev, email: location.state.email }));
    }
  }, [location]);

  const handleSignUp = async (e) => {
    e.preventDefault();

    const user = auth.currentUser;  // Get the currently signed-in user

    if (!user) {
      setError('You need to be logged in to register your business.');
      return;
    }

    try {
      // Create a Firestore document for the business owner
      const businessDocRef = doc(db, 'businesses', user.uid);
      await setDoc(businessDocRef, {
        email: formData.email,
        businessName: formData.businessName,
        location: formData.location,
        role: 'businessOwner',  // Assign the businessOwner role
        createdAt: new Date(),
      });

      setMessage('Business registered successfully!');
      navigate('/business-dashboard');  // Redirect to the business dashboard
    } catch (err) {
      setError('Failed to register: ' + err.message);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Business Sign-Up</h2>
      {message && <p className="text-green-500">{message}</p>}
      {error && <p className="text-red-500">{error}</p>}
      <form onSubmit={handleSignUp}>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Business Name:</label>
          <input
            type="text"
            value={formData.businessName}
            onChange={(e) => setFormData({ ...formData, businessName: e.target.value })}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Business Location:</label>
          <input
            type="text"
            value={formData.location}
            onChange={(e) => setFormData({ ...formData, location: e.target.value })}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <button
          type="submit"
          className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight"
        >
          Register Business
        </button>
      </form>
    </div>
  );
};

export default BusinessSignUp;