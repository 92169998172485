// src/Home.js
import React from 'react';
import coins from '../assets/coins.png';
import SignUp from './SignUp';
import RoleSignUp from '../components/RoleSignUp';

function Home() {
  return (
    <div className="min-h-screen bg-neutralLight text-neutral flex flex-col items-center justify-center">
     <div className="">
      <img src={coins} alt="NeighborCoin Logo" className="" />
      </div>
      <header className="text-neutral p-4 text-center">
        <h1 className="text-3xl font-bold">Earn Coins. <br/>Build Community.</h1>
    </header>
    <main className="p-3">
        <RoleSignUp />
       


    </main>
      
    </div>
  );
}

export default Home;