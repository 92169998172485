import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { db, auth } from '../firebase';
import { collection, getDocs, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import Map from '../components/Map';
import NClogo from '../assets/NeigbhorCoinLogo.png';
import { useNavigate } from 'react-router-dom';
import SignUp from './SignUp';  
import CoinImage from '../assets/coin_blank.png';



const Events = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [showSignup, setShowSignup] = useState(false);  // Track if signup form should be shown
  const [rsvpMessages, setRsvpMessages] = useState({});  // Object to store RSVP messages for each event
  const [currentTime, setCurrentTime] = useState(new Date());

  const handleIconClick = (eventId) => {
    setSelectedEventId(selectedEventId === eventId ? null : eventId);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsCollection = collection(db, 'events');
        const eventsSnapshot = await getDocs(eventsCollection);
        const eventsList = eventsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter(event => new Date (event.date) >= currentTime);  // Filter out archived events
        setEvents(eventsList);
      } catch (err) {
        setError('Failed to fetch events: ' + err.message);
      }
    };

    fetchEvents();
  }, [currentTime]);

// Function to categorize events
const categorizeEvents = (events) => {
  const today = new Date();
  const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));  // Sunday
  const endOfWeek = new Date(today.setDate(today.getDate() + 6));  // Saturday

  const categorized = {
    today: [],
    thisWeek: [],
    upcoming: [],
  };

  events.forEach(event => {
    const eventDate = new Date(event.date);

    if (eventDate.toDateString() === new Date().toDateString()) {
      categorized.today.push(event);
    } else if (eventDate >= startOfWeek && eventDate <= endOfWeek) {
      categorized.thisWeek.push(event);
    } else {
      categorized.upcoming.push(event);
    }
  });

  return categorized;
};

const categorizedEvents = categorizeEvents(events);

  const handleRsvp = async (event) => {
    const user = auth.currentUser;

    if (!user) {
      setShowSignup(true);  // Show signup form if user is not authenticated
      return;
    }

    try {
      const eventRef = doc(db, 'events', event.id);

      // Update the event document by adding the user's email to the RSVPs array
      await updateDoc(eventRef, {
        rsvps: arrayUnion(user.email)
      });

      // Set RSVP message only for this specific event
      setRsvpMessages(prev => ({
        ...prev,
        [event.id]: `Congratulations! You have RSVP'd to ${event.name}. You have ${event.coins} NeighborhoodCoins pending for participating.`
      }));
    } catch (err) {
      setError('Failed to RSVP: ' + err.message);
    }
  };

  const isEventStarted = (eventDate, eventStartTime) => {
    const eventDateTime = new Date(`${eventDate} ${eventStartTime}:00`);
    return currentTime > eventDateTime;
  };

  const getEventLabel = (ticketPrice) => {
    return ticketPrice && ticketPrice > 0 ? "PAID" : "FREE";
  };

  const renderEvents = (eventsList, categoryTitle) => {
    if (eventsList.length === 0) return null;

    return (
      <>
        <h3 className="text-2xl bg-lightPurple mt-4 py-4 px-4 text-accent text-shadow mb-4">{categoryTitle}</h3>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6">
          {eventsList.map(event => (
            <div key={event.id} className="p-4 bg-gray-100 rounded-lg shadow-md">
              <div className="relative">
              <img src={event.imageUrl} alt={event.name} className="w-full h-48 object-cover rounded-t-lg mb-4" />
              <div className="absolute bottom-2 right-2 text-white py-1 px-3 rounded-full flex items-center justify-center w-20 h-20" style={{
              backgroundImage: `url(${CoinImage})`,
              backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}>
              <p className="text-center">
                <small className="block text-sm m-0 text-shadow">Claim</small>
                <strong className="block text-2xl m-0 text-shadow">
                  <span className="block text-shadow" >{event.coins}</span> 
                </strong>
                <span className="block text-sm mt-0 text-shadow"> Coins</span> </p>
            </div>
              </div>
              <h4 className="text-2xl font-semibold text-accent mb-2">{event.name}</h4>
              
              <p className="text-neutral mb-2">
                <span className="font-bold">Date:</span>{' '}
                <span className="font-medium font-bold">
                  {(() => {
                    const date = event.date instanceof Object && event.date.toDate 
                      ? event.date.toDate() 
                      : new Date(event.date);
                    return new Date(date).toLocaleDateString('en-US', {
                      year: 'numeric', 
                      month: 'long', 
                      day: '2-digit',
                      timeZone: 'UTC'
                    });
                  })()}
                </span>
              </p>

              <p className="text-neutral mb-2">
                <span className="font-bold">Neighborhood:</span>{' '}
                <span className="font-medium ml-2">{event.neighborhood}</span>
              </p>
              <p className="text-neutral mb-2">
                <span className="font-bold">Price:</span> <span className="font-medium">{getEventLabel(event.ticketPrice)}</span>
              </p>
              <p>
                <span className="font-bold">Map:</span>
                <FontAwesomeIcon 
                  icon={faMapMarkerAlt} 
                  className="text-primary ml-2 cursor-pointer" 
                  onClick={() => handleIconClick(event.id)} 
                />
              </p>
           
              <div>
                <button
                  onClick={() => navigate(`/events/${event.id}`)}  // Navigate to the Event Details page
                  className="bg-secondary text-white py-2 m-3 px-4 rounded hover:bg-highlight transition-colors duration-300"
                >
                  More Details
                </button>
              </div>
              <div>
                {/* Disable or hide RSVP button if event has already started */}
                {isEventStarted(event.date, event.startTime) ? (
                  <p className="text-red-500">Event has already started. RSVPs have ended.</p>
                ) : (
                  <button
                    onClick={() => handleRsvp(event)}  // RSVP instead of Join
                    className="bg-primary text-white py-2 px-4 m-3 rounded hover:bg-highlight transition-colors duration-300"
                  >
                    Join Event
                  </button>
                )}
              </div>


              {/* Show the RSVP message only for this event if it exists */}
              {rsvpMessages[event.id] && <strong><p className="text-primary mt-4">{rsvpMessages[event.id]}</p></strong>}

              {/* Conditionally render the map within the card if this event is selected */}
              {selectedEventId === event.id && (
                <div className="mt-4">
                  <Map event={event} />
                </div>
              )}
            </div>
          ))}
          
        </div>
      </>
    );
  };


  return (
    <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-primary mb-6 text-center">Miami Community Events</h2>
      <h5 className="text-x1 text-teal font-bold mb-4 text-center">Earn Neighborhood Coins</h5>

      {/* Conditionally render the Signup form if user is not authenticated */}
      {showSignup && (
        <div className="mt-6">
          <h3 className="text-xl font-bold text-primary mb-4">Please Sign Up to RSVP</h3>
          <SignUp />  {/* Display the SignUp component */}
        </div>
      )}
      {renderEvents(categorizedEvents.today, 'Today')}
      {renderEvents(categorizedEvents.thisWeek, 'This Week')}
      {renderEvents(categorizedEvents.upcoming, 'Upcoming Events')}

    </div>
  );
};

export default Events;