import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase'; 
import Layout from './components/Layout';
import SignUp from './pages/SignUp';
import Home from './pages/Home';
import FinishSignUp from './pages/FinishSignUp';
import Dashboard from './pages/Dashboard';
import Events from './pages/Events';
import Rewards from './pages/Rewards';
import Profile from './pages/Profile';
import EventDetails from './pages/EventDetails';
import EventCalendar from './components/EventCalendar.js';
import ProtectedRoute from './components/ProtectedRoute';
import EventForm from './components/EventForm';
import EditEventForm from './components/EditEventForm';
import HowItWorks from './components/HowItWorks';
import SignUps from './components/SignUps.js';
import RoleSignUp from './components/RoleSignUp.js';
import BusinessSignUp from './components/BusinessSignUp.js';
import BusinessDashboard from './pages/BusinessDashboard.js';
import EditBusinessProfile from './components/EditBusinessProfile.js';
import Login from './pages/Login.js';
import CreateOffer from './components/CreateOffer.js';
import { Helmet } from 'react-helmet-async';


function App() {
  const[user, loading, error] = useAuthState(auth);
  const isAdmin = false;
  if(user === 'christine@unieros.com'){
    isAdmin = true;
  }
  if (loading) {
    // You can replace this with a spinner or any loading indicator
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <Router>
      <div className="min-h-screen bg-neutralLight text-neutral flex flex-col items-center justify-center">
     <Layout>
      <main className="p-6">
        <Routes>
         <Route path="/" element={<Home />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/signup" element={<RoleSignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/finishSignUp" element={<FinishSignUp />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/events" element={<Events />} />
    
          <Route path="/events/:eventId" element={<EventDetails />} /> 
          <Route path="/edit-event/:eventId" element={<EditEventForm />} /> 
          <Route path="/signups" element={<SignUps />} />
          <Route path="/rewards" element={<Rewards />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/business-dashboard" element={<BusinessDashboard />} />
          <Route path="/business-profile-setup" element={<BusinessSignUp />} />
          <Route path="/edit-business-profile" element={<EditBusinessProfile />} />
          <Route path="/create-offer" element={<CreateOffer />} />
          <Route
                path="/event-admin"
                element={
                  <ProtectedRoute>
                    <EventForm />
                  </ProtectedRoute>
                }
            />    
        </Routes>
        </main>

       
        </Layout>
      </div>
    </Router>
  );
}

export default App;